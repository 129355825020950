import * as React from 'react';
import DefaultLayout from "../../components/layouts/DefaultLayout/DefaultLayout";
import FeedbackDialog from "./FeedbackDialog";
import ShippingAddressDialog from "./ShippingAddressDialog";
import {useSelector} from "react-redux";
import CancellationDialog from "../checkout/components/CancellationDialog";
import OrderCreatedDialog from "../checkout/components/OrderCreatedDialog";
import OrderCanceledDialog from "../checkout/components/OrderCanceledDialog";
import Stack from "@mui/material/Stack";
import SharePresentationLinkDialog from "./SharePresentationLinkDialog";
import CustomQuoteVersions from "./CustomQuoteVersions";
import SubmissionInfo from "./SubmissionInfo";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import OriginalSubmissionDialog from "./OriginalSubmissionDialog";
import {useNavigate} from "react-router-dom";
import Divider from "@mui/material/Divider";
import { useParams } from 'react-router-dom';
import {
    cancelCustomQuoteAction,
    convertCustomQuoteToOrderAction,
    getCustomQuoteAction,
    getCustomQuoteVersionFeedbacksAction,
    storeCustomQuoteVersionFeedbackAction
} from "../../store/actions/customQuotes";
import VersionMedia from "./VersionMedia";
import VersionDetails from "./VersionDetails";
import VersionFeedbacks from "./VersionFeedbacks";
import AlertComponent from "../../components/AlertComponent";
import { getJsonObject } from "../../utils/helpers";
import CustomQuoteActionButtons from "./CustomQuoteActionButtons";
import Typography from "@mui/material/Typography";
import WarningSubmitCancelDialog from "../../components/WarningSubmitCancelDialog";


const CustomQuoteDetailsPage = () => {
    const customQuote = useSelector(store => store.customQuotes.selectedItem);
    const feedbacks = useSelector(store => store.customQuotes.feedbacks);
    const activeStore = useSelector(store => store.stores.activeStore);
    const currentUser = useSelector(store => store.auth.authenticatedUser);

    const { id } = useParams();

    const [recipient, setRecipient] = React.useState('store');

    const shippingAddress = recipient === 'company' ?
        activeStore?.company.shipping_address : activeStore?.shipping_address;

    const [isCustomQuoteLoaded, setIsCustomQuoteLoaded] = React.useState(false);
    const [openFeedbackDialog, setOpenFeedbackDialog] = React.useState(false);
    const [openSharePresentationLinkDialog, setOpenSharePresentationLinkDialog] = React.useState(false);
    const [openOriginalSubmissionDialog, setOpenOriginalSubmissionDialog] = React.useState(false);
    const [openShippingAddressDialog, setOpenShippingAddressDialog] = React.useState(false);
    const [openCancellationDialog, setOpenCancellationDialog] = React.useState(false);
    const [openOrderCanceledDialog, setOpenOrderCanceledDialog] = React.useState(false);
    const [openOrderCreatedDialog, setOpenOrderCreatedDialog] = React.useState(false);
    const [openCancelSubmissionDialog, setOpenCancelSubmissionDialog] = React.useState(false);

    const [selectedVersion, setSelectedVersion] = React.useState(undefined);

    const navigate = useNavigate();

    React.useEffect(() => {
        if (!activeStore) {
            return;
        }

        if (!activeStore.enable_custom_quotes) {
            navigate('/', {replace: true});
        }
    }, [activeStore, navigate]);

    React.useEffect(() => {
        const getCustomQuote = async () => {
            try {
                await getCustomQuoteAction(id);
                setIsCustomQuoteLoaded(true);
            } catch (e) {
                AlertComponent.error(e.response.data.message);
            }
        };

        if (!isCustomQuoteLoaded) {
            getCustomQuote();
        }
    }, [id, isCustomQuoteLoaded]);

    React.useEffect(() => {
        if (isCustomQuoteLoaded) {
            setSelectedVersion(customQuote?.versions?.[0]);
        }
    }, [customQuote, isCustomQuoteLoaded]);

    React.useEffect(() => {
        const getFeedbacks = async () => {
            try {
                await getCustomQuoteVersionFeedbacksAction(selectedVersion.custom_quote_id, selectedVersion.id);
            } catch (e) {
                AlertComponent.error(e.response.data.message);
            }
        };

        if (selectedVersion?.id) {
            getFeedbacks();
        }
    }, [selectedVersion]);


    const createOrderHandler = async () => {
        setOpenCancellationDialog(false);

        const decodedAddress = getJsonObject(shippingAddress);

        const data = {
            recipient: JSON.stringify({
                type: recipient,
                value: recipient === 'store' ? activeStore.shipping_name || activeStore.name :
                    activeStore.company.shipping_name
            }),
            address_line_1: decodedAddress.address_line_1 || null,
            address_line_2: decodedAddress.address_line_2 || null,
            city: decodedAddress.city || null,
            state: decodedAddress.state || null,
            postal_code: decodedAddress.postal_code || null,
            country: decodedAddress.country || null,
            store_id: activeStore.id,
            version_id: selectedVersion.id
        };

        try {
            await convertCustomQuoteToOrderAction(selectedVersion.custom_quote_id, data);

            setOpenOrderCreatedDialog(true);
        } catch (e) {
            AlertComponent.error(e.response.data.message);
        }
    };

    const details = getJsonObject(selectedVersion?.details);
    const media = getJsonObject(selectedVersion?.media);

    const storeFeedbackHandler = async (data) => {
        try {
            await storeCustomQuoteVersionFeedbackAction(selectedVersion.custom_quote_id, selectedVersion.id, data);
            setOpenFeedbackDialog(false);
            setIsCustomQuoteLoaded(false);
            await getCustomQuoteAction(id);
        } catch (e) {
            AlertComponent.error(e.response.data.message);
        }

        setIsCustomQuoteLoaded(true);
    };

    const cancelCustomQuoteHandler = async () => {
        try {
            await cancelCustomQuoteAction(customQuote.id);
            navigate(`/custom-quotes`);
        } catch (e) {
            AlertComponent.error(e.response.data.message);
        }

        setIsCustomQuoteLoaded(true);
    };

    return (
        <DefaultLayout>
            <Grid container
                spacing={2}
                sx={{
                    height: '100%'
                }}
            >
                <Grid item
                    xs={3}
                >
                    <Stack
                        spacing={2}
                        sx={{
                            height: '100%'
                        }}
                    >
                        <SubmissionInfo
                            currentUser={currentUser}
                            customQuote={isCustomQuoteLoaded ? customQuote : {}}
                        />

                        <CustomQuoteVersions
                            isLoaded={isCustomQuoteLoaded}
                            currentUser={currentUser}
                            versions={customQuote.versions}
                            selectedVersion={selectedVersion}
                            setSelectedVersion={setSelectedVersion}
                        />
                    </Stack>
                </Grid>
                <Grid item
                    xs={9}
                >
                    <Paper
                        variant={'outlined'}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <CustomQuoteActionButtons
                            selectedVersion={selectedVersion}
                            currentUserRole={currentUser?.role}
                            customQuoteStatus={customQuote?.status}
                            feedbacks={feedbacks}
                            isCustomQuoteLoaded={isCustomQuoteLoaded}
                            setOpenFeedbackDialog={setOpenFeedbackDialog}
                            setOpenOriginalSubmissionDialog={setOpenOriginalSubmissionDialog}
                            setOpenSharePresentationLinkDialog={setOpenSharePresentationLinkDialog}
                            setOpenShippingAddressDialog={setOpenShippingAddressDialog}
                            setOpenCancelSubmissionDialog={setOpenCancelSubmissionDialog}
                        />

                        <Divider />

                        {isCustomQuoteLoaded && !selectedVersion &&
                            <Stack
                                spacing={1}
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexGrow: 1
                                }}
                            >
                                <Typography
                                    variant='h4'
                                    fontWeight='bold'
                                    gutterBottom
                                >
                                    Quote in Progress
                                </Typography>
                                <Typography
                                    variant='body1'
                                    color='text.secondary'
                                >
                                    The request is under review. Quote versions will appear here once a response has been provided.
                                </Typography>
                            </Stack>
                        }

                        {selectedVersion &&
                            <Grid container
                                  sx={{
                                      p: 2,
                                      pt: 4,
                                  }}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    sx={{
                                        pl: 2,
                                        pr: 2
                                    }}
                                >
                                    <VersionMedia
                                        media={media}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <Stack
                                        spacing={2}
                                    >
                                        {isCustomQuoteLoaded && feedbacks?.length > 0 &&
                                        <VersionFeedbacks
                                            currentUser={currentUser}
                                            feedbacks={feedbacks}
                                        />
                                        }
                                        {isCustomQuoteLoaded && Object.keys(details || {}).length > 0 &&
                                        <VersionDetails
                                            details={details}
                                        />
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        }
                    </Paper>
                </Grid>
            </Grid>


            {openFeedbackDialog &&
                <FeedbackDialog
                    title={`Feedback Submission for version ${selectedVersion.name}`}
                    closeHandler={() => setOpenFeedbackDialog(false)}
                    successHandler={storeFeedbackHandler}
                    maxUploadedFilesNumber={5}
                    maxUploadedFileSize={10}
                />
            }

            {openShippingAddressDialog &&
                <ShippingAddressDialog
                    shippingAddress={shippingAddress}
                    activeStore={activeStore}
                    recipient={recipient}
                    changeRecipientHandler={setRecipient}
                    closeHandler={() => setOpenShippingAddressDialog(false)}
                    successHandler={() => {
                        setOpenShippingAddressDialog(false);
                        setOpenCancellationDialog(true);
                    }}
                />
            }

            {openCancellationDialog &&
                <CancellationDialog
                    mainText={'Order submission in progress...'}
                    bottomText={'Do not refresh or close this page until your order is submitted.'}
                    cancelButtonText={'Cancel Order'}
                    createOrderHandler={createOrderHandler}
                    closeDialogHandler={() => {
                        setOpenCancellationDialog(false);
                        setOpenOrderCanceledDialog(true);
                    }}
                />
            }

            {openOrderCreatedDialog &&
                <OrderCreatedDialog
                    redirectPath={'/orders-history'}
                    mainText={'Your order has been placed!'}
                    bottomText={'Redirecting you to the orders history page.'}
                    showProgressBar={true}
                />
            }

            {openOrderCanceledDialog &&
                <OrderCanceledDialog
                    mainText={'Canceling order submission...'}
                    closeDialogHandler={() => setOpenOrderCanceledDialog(false)}
                />
            }

            {openSharePresentationLinkDialog &&
                <SharePresentationLinkDialog
                    currentUser={currentUser}
                    customQuoteVersion={selectedVersion}
                    closeHandler={() => setOpenSharePresentationLinkDialog(false)}
                />
            }

            {openOriginalSubmissionDialog && isCustomQuoteLoaded &&
                <OriginalSubmissionDialog
                    customQuote={customQuote}
                    closeHandler={() => setOpenOriginalSubmissionDialog(false)}
                />
            }

            {openCancelSubmissionDialog &&
                <WarningSubmitCancelDialog
                    closeHandler={() => setOpenCancelSubmissionDialog(false)}
                    title={'Are you sure you want to cancel?'}
                    content={'Canceling will discard all your progress on this quote submission. This action cannot be undone. Would you like to continue?'}
                    actionHandler={cancelCustomQuoteHandler}
                    actionButtonText={'Cancel Quote'}
                    closeButtonText={'Go Back'}
                />
            }

        </DefaultLayout>
    );
};

export default CustomQuoteDetailsPage;