import * as React from 'react';
import DeleteIconOutlined from '@mui/icons-material/DeleteOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {generatePreview, findPreviewComponent} from "../../utils/helpers";

const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
const ALLOWED_EXTENSIONS = ['.png', '.jpg', '.jpeg', '.pdf'];

const FeedbackForm = ({ formTitle, formDescription, selectedFiles, setSelectedFiles, comment, setComment, maxUploadedFilesNumber, maxUploadedFileSize }) => {
    const [moreFilesThanAllowed, setMoreFilesThanAllowed] = React.useState(false);
    const [filePreviews, setFilePreviews] = React.useState([]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);

        const filteredFiles = files.filter((file) => {
            const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
            const isAllowedType = ALLOWED_FILE_TYPES.includes(file.type);
            const isAllowedExtension = ALLOWED_EXTENSIONS.includes(fileExtension);
            const isAllowedBySize = maxUploadedFileSize ? file.size <= maxUploadedFileSize * 1024 * 1024 : true;

            if (!isAllowedType || !isAllowedExtension || !isAllowedBySize) {
                alert(`File ${file.name} is not allowed.`);
                return false;
            }

            return true;
        });

        const totalFilesNumber = filteredFiles.length + selectedFiles.length;

        setMoreFilesThanAllowed(totalFilesNumber >= maxUploadedFilesNumber);

        setSelectedFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            let availableQuantity = maxUploadedFilesNumber - prevFiles.length;

            for (const singleFile of filteredFiles) {
                if (availableQuantity <= 0) {
                    break;
                }

                generatePreview(singleFile, setFilePreviews);

                newFiles.push(singleFile);
                availableQuantity -= 1;
            }

            return newFiles;
        });

        event.target.value = '';
    };

    const handleDeleteFile = (fileToDelete) => {
        setMoreFilesThanAllowed(false);
        setSelectedFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
        setFilePreviews((prev) => prev.filter((preview) => preview.fileName !== fileToDelete.name));
    };

    return (
        <>
            {formTitle &&
                <Typography
                    variant={'h6'}
                    sx={{
                        fontWeight: 600
                    }}
                >
                    {formTitle}
                </Typography>
            }

            <Box
                sx={{
                    margin: '10px 0'
                }}
            >
                {formDescription &&
                    <Typography
                        variant={'body1'}
                    >
                        {formDescription}
                    </Typography>
                }
            </Box>

            <Paper
                variant={'outlined'}
                sx={{
                    padding: '16px',
                }}
            >
                <Stack spacing={2}>
                    <Typography
                        variant={'body1'}
                    >
                        Upload Files
                    </Typography>

                    {selectedFiles.length > 0 &&
                    <Stack spacing={1}>
                        {selectedFiles.map((file, index) => (
                            <Card
                                key={index}
                                variant={'outlined'}
                                sx={{
                                    padding: '5px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    {findPreviewComponent(file.name, filePreviews)}

                                    <Typography
                                        variant='h6'
                                    >
                                        {file.name}
                                    </Typography>
                                </Box>


                                <IconButton
                                    onClick={() => handleDeleteFile(file)}
                                    aria-label='Delete'
                                >
                                    <DeleteIconOutlined/>
                                </IconButton>
                            </Card>
                        ))}
                    </Stack>
                    }

                    {moreFilesThanAllowed &&
                        <Typography
                            variant={'body1'}
                            color={'red'}
                        >
                            You can't upload more files.
                        </Typography>
                    }

                    <Button
                        sx={{
                            alignSelf: 'start'
                        }}
                        component="label"
                        variant="outlined"
                        startIcon={<FileUploadOutlinedIcon />}
                        disabled={moreFilesThanAllowed}
                    >
                        Click to Upload
                        <input
                            type="file"
                            multiple
                            accept={ALLOWED_FILE_TYPES.join(',')}
                            hidden
                            onChange={handleFileChange}
                        />
                    </Button>

                    <Typography
                        color={'secondary'}
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        {`You can attach up to ${maxUploadedFilesNumber} files (JPG, PNG or PDF), and each file should be no bigger than ${maxUploadedFileSize} MB.`}
                    </Typography>

                    <Box>
                        <Typography
                            variant="h6"
                            color={'secondary'}
                            sx={{
                                mb: '5px'
                            }}
                        >
                            Feedback Comments
                            <Box
                                component="span"
                                sx={{
                                    color: 'red',
                                    ml: 0.5,
                                    lineHeight: 1,
                                    fontWeight: 600,
                                    fontSize: '1.2em'
                                }}
                            >
                                *
                            </Box>
                        </Typography>
                        <TextField
                            value={comment}
                            onChange={e => setComment(e.target.value)}
                            multiline
                            rows={4}
                            fullWidth
                            sx={{
                                '& > div': {
                                    p: '5px 10px'
                                }
                            }}
                        />
                    </Box>
                </Stack>
            </Paper>
        </>
    );
};

export default FeedbackForm;